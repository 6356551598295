const prefix = 'api';

enum BackEndpoints {
  // OLD
  WP_CONTINENTS = 'wp-continents',
  WP_EVENTS = 'wp-events-cultures', // todo: delete it
  LOCATIONS = 'locations',
  HOTELS = 'hotel-lmdvs',
  WP_PAGES = 'wp-pages',
  COMMON_COMPONENTS = 'common-components-lmdvs',

  // Data
  ARTICLES = 'articles',
  ARTICLE_TAGS = 'article-tags',
  EVENTS = 'events',
  EVENT_TAGS = 'event-tags',
  GEOGRAPHIC_ZONES = 'geographic-zones',
  COUNTRIES = 'countries',
  INSPIRATIONS = 'inspirations',
  JOURNEYS = 'journeys',
  JOURNEY_DEPARTURES = 'journey-departures',
  EXPERTS = 'experts',
  CATEGORIES = 'categories',
  CATEGORIES_LIMITED = 'categories/limited-journeys',
  HEADER = 'header',
  BUSINESS_HEADER = 'business-header',
  SEA_PAGE = 'sea-pages',
  SEA_UTM = 'sea-utms',
  WHERE_TO_GO = 'where-to-gos',
  WHEN_TO_GO = 'when-to-gos',
  FORMALITY = 'formalities',
  TRAVEL_CULTURE = 'travel-cultures',
  USEFUL_INFORMATION = 'useful-informations',

  // pages
  HOME_PAGE = 'home-page',
  EXPERT_PAGE = 'expert-listing-page',
  JOURNEY_PAGE = 'journey-page',
  COMMON = 'common',
  GEOGRAPHIC_ZONE_PAGE = 'geographic-zone-page',
  JOURNEY_SEARCH_PAGE = 'journey-search-page',
  TAILOR_MADE_QUOTE_PAGE = 'tailor-made-quote-page'
}

export { BackEndpoints, prefix };
